import React, { type SetStateAction, useMemo, useState, useCallback } from 'react';
import { ContextMenuProvider } from '../../../../../../contexts/ContextMenuContext';
import ContextMenuViewer, { type ContextMenuItemType } from '../../../../../../components/ContextMenuViewer';
import { type SortableOrderField, type Order } from '../../../../../../types/OrderTypes';
import { IconsCatalog } from '../../../../../../components/IconsCatalog';
import { type SublistType } from '../../../../../../types/SublistTypes';
import FooterDetailsForPrintScreen from '../FooterDetailsForPrintScreen';
import { type ClothingModelType } from '../../../../../../types/ClothingModelType';
import { toast } from 'react-hot-toast-promise';
import { type ProjectReportType } from '../../../../../../types/ProjectReportType';
import { deleteOrder, updateCachedOrderInSublist } from '../../../services/orderService';
import { type UserPreferences } from '../../../../../../types/UserPreferences';
import { type HandleSortOrderParams } from '../../../../../../types/HandleSortOrderParams';
import StickyMenuBar from '../../../../../../components/Menu/StickyMenuBar';
import SplitButton from '../../../../../../components/Buttons/SplitButton';
import { type SortByClothingSizeParams } from '../../../../../../types/SortByClothingSizeParams';
import ConfirmationModal from '../../../../../../components/Modals/ConfirmationModal';
import SublistPickerModal from '../../../../../../components/Modals/SublistPickerModal';
import { deleteMultipleOrders, moveSelectedOrdersToSublist } from '../../services/orderFormService';
import CollapsableCard from '../../../../../../components/Cards/CollapsableCard';
import { type FinalClientEditorSettings } from '../../../../../../types/FinalClientEditor/FinalClientEditorSettings';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { copyToClipboard, getServerErrorMessageFromResponse } from '../../../../../../utils/helper';
import { type SublistCollapseControlType } from '../../../../../../types/SublistCollapseControlType';
import { generateSublistCsvFullUrl, generateSublistJsonFullUrl } from '../../../services/sublistService';
import Tabs from '../../../../../../components/Tabs';
import TableOrderEditor from './components/TableOrderEditor';
import ExclusivePricingSublistEditor, { type ModelPricingInformation } from './components/ExclusivePricingSublistEditor';
import { type UserType } from '../../../../../../types/UserType';
import SublistFinishingOptionsViewer from '../../../ProjectReport/components/SublistFinishingOptionsViewer';
import { WrapperList } from './style';
import { type KanbanCard } from '../../../../../../types/Kanban/KanbanCard';
import { DismemberInCard } from './components/DismemberInCard';
import { SeamstressTable } from './components/SeamstressTable';
import _ from 'lodash';
import SublistCollapsableCardHeader from './components/SublistCollapsableCardHeader';
import { deleteOrdersFromSublist } from '../../../api/deleteOrders';
import { useHttpRequest } from '../../../../../../contexts/HttpRequestContext';
import { moveOrdersToSublist } from '../../../api/moveOrders';
import { RawMaterialTable } from './components/RawMaterialTable';
import { sortOrdersInSublist } from './api/sublistSortingConfig';
import { updateSublistInCachedSublists } from '../../services/sublistService';
import OrderClothingReplacementManagerModal from './components/OrderClothingReplacementManagerModal';

export type TableOrderManagerType = {
  user?: UserType;
  setOrder: React.Dispatch<SetStateAction<Order>>;
  isEditingOrder: boolean;
  sublists: SublistType[];
  setSublists: React.Dispatch<SetStateAction<SublistType[]>>;
  importedModels: ClothingModelType[];
  projectReport?: ProjectReportType;
  projectId: number;
  preferences?: UserPreferences;
  screenshotMode: boolean;
  isCompanyEditor?: boolean;
  editorSettings?: FinalClientEditorSettings;
  isSynching: boolean;
  sublistsContainerRef: React.RefObject<HTMLDivElement>;
  hideExclusiveModelPricingTab?: boolean;
  onSublistCollapseChange?: (sublistCollapseEvent: SublistCollapseControlType) => void;
  card?: KanbanCard;
  startWithClosedPanel?: boolean;
  onUpdateSublists: (updatedSublists: SublistType[]) => void;
};

export default function TableOrderManager({
  user,
  setOrder,
  isEditingOrder,
  sublists,
  setSublists,
  sublistsContainerRef,
  importedModels,
  projectReport,
  projectId,
  preferences,
  screenshotMode,
  isCompanyEditor,
  isSynching,
  editorSettings,
  hideExclusiveModelPricingTab,
  onSublistCollapseChange = () => {},
  card,
  startWithClosedPanel,
  onUpdateSublists
}: TableOrderManagerType) {
  const [selectionMode, setSelectionMode] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
  const [isMovingMode, setIsMovingMode] = useState(false);
  const [showConfirmDeleteSelectedOrders, setShowConfirmDeleteSelectedOrders] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState<Order | null>(null);
  const [orderToManageClothingReplacement, setOrderToManageClothingReplacement] = useState<Order | null>(null);

  const { Translate } = useAppTranslation();
  const { httpConnection } = useHttpRequest();

  const urlToken = useMemo<string | null>(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('t');
  }, []);

  const finalClientAccessToken = useMemo(() => {
    return (!isCompanyEditor && urlToken) ? urlToken : undefined;
  }, [isCompanyEditor, urlToken]);

  const handleCopySublistUrl = useCallback(
    async (sublist: SublistType) => {
      try {
        if (!sublist.token || !sublist.project_id) return;

        const url = generateSublistJsonFullUrl(sublist.project_id, sublist.token);
        await copyToClipboard(url);
        toast.success(Translate('toast.link-copied-sisbolt'));
      } catch (err) {
        toast.error(Translate('error.failed-to-copy-link'));
      }
    },
    [Translate]
  );

  const handleCopySublistUrlCSV = useCallback(
    async (sublist: SublistType) => {
      try {
        if (!sublist.token || !sublist.project_id) return;

        const url = generateSublistCsvFullUrl(sublist.project_id, sublist.token);
        await copyToClipboard(url);
        toast.success(Translate('toast.link-copied-sisbolt'));
      } catch (err) {
        toast.error(Translate('error.failed-to-copy-link'));
      }
    },
    [Translate]
  );

  const handleExitSelectionMode = useCallback(() => {
    setSelectionMode(false);
    setSelectedOrders([]);
    toast.error(Translate('toast.selection-mode-disabled'));
  }, [Translate]);

  const handleSortByClothingSize = useCallback(
    ({ clotheIndex, sublist }: SortByClothingSizeParams) => {
      const clotheSortingKey = `clothe-${clotheIndex}` as SortableOrderField['field'];

      const saveSublistSortingConfigPromise = sortOrdersInSublist({
        httpConnection,
        projectId,
        sublistId: sublist.id!,
        lastSortedField: clotheSortingKey.toString()
      });

      toast.promise(saveSublistSortingConfigPromise, {
        loading: Translate('progress.loading'),
        success: (sortedOrders) => {
          const updatedSublist: SublistType = { ...sublist, orders: sortedOrders };
          const updatedSublists = updateSublistInCachedSublists({ sublist: updatedSublist, sublists });
          onUpdateSublists(updatedSublists);
          return Translate('toast.done');
        },
        error: getServerErrorMessageFromResponse
      });
    },
    [httpConnection, projectId, Translate, sublists, onUpdateSublists]
  );

  const handleSortOrder = useCallback(
    ({ field, sublists, sublist }: HandleSortOrderParams) => {
      if (editorSettings?.final_client_readonly) return;

      const saveSublistSortingConfigPromise = sortOrdersInSublist({
        httpConnection,
        projectId,
        sublistId: sublist.id!,
        lastSortedField: field.toString()
      });

      toast.promise(saveSublistSortingConfigPromise, {
        loading: Translate('progress.loading'),
        success: (sortedOrders) => {
          const updatedSublist: SublistType = { ...sublist, orders: sortedOrders };
          const updatedSublists = updateSublistInCachedSublists({ sublist: updatedSublist, sublists });

          onUpdateSublists(updatedSublists);
          return Translate('toast.done');
        },
        error: getServerErrorMessageFromResponse
      });
    },
    [editorSettings?.final_client_readonly, Translate, httpConnection, projectId, onUpdateSublists]
  );

  const handleDeleteMultipleOrders = useCallback(() => {
    const deleteOrdersPromise = deleteOrdersFromSublist({
      httpConnection,
      projectId,
      selectedOrdersToDelete: selectedOrders,
      finalClientAccessToken
    });

    toast.promise(deleteOrdersPromise, {
      loading: Translate('progress.loading'),
      success: deletedOrderIds => {
        const updatedSublists = deleteMultipleOrders({ sublists, deletedOrderIds });
        onUpdateSublists(updatedSublists);
        setSelectionMode(false);
        setShowConfirmDeleteSelectedOrders(false);
        setSelectedOrders([]);
        return Translate('toast.selected-items-removed');
      },
      error: getServerErrorMessageFromResponse
    });
  }, [Translate, finalClientAccessToken, httpConnection, onUpdateSublists, projectId, selectedOrders, sublists]);

  const handleMoveSelectedOrdersToSublist = useCallback(
    (sublist_id: number) => {
      const filteredSelectedOrders = selectedOrders.filter(
        currentSelectedOrder => currentSelectedOrder.sublist_id !== sublist_id
      );

      if (filteredSelectedOrders.length === 0) {
        toast.error(Translate('error.nothing-to-move-orders-already-in-destination-sublist'));
        return;
      }

      const moveOrdersToSublistPromise = moveOrdersToSublist({
        httpConnection,
        projectId,
        orders: filteredSelectedOrders,
        destinationSublistId: sublist_id
      });

      toast.promise(moveOrdersToSublistPromise, {
        error: getServerErrorMessageFromResponse,
        loading: Translate('progress.loading'),
        success: () => {
          const updatedSublists = moveSelectedOrdersToSublist({ selectedOrders, sublist_id, sublists });
          onUpdateSublists(updatedSublists);

          setSelectedOrders([]);
          setIsMovingMode(false);

          return Translate('toast.item-moved');
        }
      });
    },
    [selectedOrders, httpConnection, projectId, Translate, sublists, onUpdateSublists]
  );

  const handleCancelMoveOrders = useCallback(() => {
    setIsMovingMode(false);
    setSelectedOrders([]);
  }, []);

  const handlePrepareOrderEdit = useCallback(
    (orderToEdit: Order) => {
      setOrder(orderToEdit);
    },
    [setOrder]
  );

  const handleDeleteOrder = useCallback(() => {
    if (orderToDelete === null) return;

    const deleteOrderPromise = deleteOrdersFromSublist({
      httpConnection,
      projectId,
      selectedOrdersToDelete: [orderToDelete],
      finalClientAccessToken
    });

    toast.promise(deleteOrderPromise, {
      loading: Translate('progress.loading'),
      success: deletedOrderIds => {
        const updatedSublists = deleteOrder({
          sublists,
          deletedOrderId: deletedOrderIds[0], // deleted a single order
          targetSublistId: orderToDelete.sublist_id!
        });

        onUpdateSublists(updatedSublists);
        setOrderToDelete(null);
        return Translate('toast.order-deleted');
      },
      error: getServerErrorMessageFromResponse
    });
  }, [orderToDelete, httpConnection, projectId, finalClientAccessToken, Translate, sublists, onUpdateSublists]);

  const handleUpdateCachedSublistModelPricingInformations = useCallback(
    (
      sublistToUpdate: SublistType,
      updatedModelPricingInformations: ModelPricingInformation[],
      useExclusiveModelPricing: boolean
    ) => {
      const updatedSublists = sublists.map(sublist => {
        if (sublist.id === sublistToUpdate.id) {
          return {
            ...sublist,
            exclusive_model_pricing: updatedModelPricingInformations,
            use_exclusive_model_pricing: useExclusiveModelPricing
          };
        }
        return sublist;
      });

      setSublists(updatedSublists);
    },
    [setSublists, sublists]
  );

  const screenshotStyleSheet = useMemo<React.CSSProperties>(
    () => ({ backgroundColor: '#fff', position: 'absolute', top: 0, left: 0, right: 0, zIndex: 1000, padding: '25px' }),
    []
  );

  const shouldHidePrices = useMemo(() => {
    const shouldHideForScreenshot = (screenshotMode && preferences?.screenshot_hide_prices) ?? false;
    const shouldHideFinalClientMoldsPrices = editorSettings?.final_client_hide_molds_prices ?? false;

    return shouldHideFinalClientMoldsPrices || shouldHideForScreenshot;
  }, [editorSettings?.final_client_hide_molds_prices, preferences?.screenshot_hide_prices, screenshotMode]);

  const contextMenuOptionsForOrder = useMemo(
    (): ContextMenuItemType[] => [
      {
        name: Translate('actions.edit'),
        icon: IconsCatalog.pen,
        handleClick: selectedOrder => {
          handlePrepareOrderEdit(selectedOrder as Order);
        }
      },
      {
        name: Translate('actions.move'),
        icon: IconsCatalog.move,
        handleClick: selectedOrder => {
          setSelectedOrders([selectedOrder as Order]);
          setIsMovingMode(true);
        }
      },
      {
        name: Translate('actions.delete'),
        icon: IconsCatalog.trash,
        handleClick: selectedOrder => {
          setOrderToDelete(selectedOrder as Order);
        }
      },
      {
        name: Translate('actions.selection-mode'),
        icon: IconsCatalog.checkSquare,
        handleClick: () => {
          setSelectionMode(true);
        }
      },
      {
        name: Translate('labels.pending'),
        icon: IconsCatalog.redo,
        hidden: !isCompanyEditor,
        handleClick: (selectedOrder) => {
          setSelectedOrders([selectedOrder as Order]);
          setOrderToManageClothingReplacement(selectedOrder as Order);
        }
      }
    ],
    [Translate, handlePrepareOrderEdit, isCompanyEditor]
  );

  return (
    <React.Fragment>
      <ConfirmationModal
        title={Translate('labels.confirmation')}
        message={Translate('description.confirm-remove-order')}
        style='danger'
        visible={orderToDelete !== null}
        handleClose={() => {
          setOrderToDelete(null);
        }}
        handleConfirm={handleDeleteOrder}
      />

      <ConfirmationModal
        title={Translate('labels.confirmation')}
        message={Translate('description.confirm-remove-selected-orders')}
        style='danger'
        visible={showConfirmDeleteSelectedOrders}
        handleClose={() => {
          setShowConfirmDeleteSelectedOrders(false);
        }}
        handleConfirm={handleDeleteMultipleOrders}
      />

      <SublistPickerModal
        title={Translate('actions.move')}
        message={Translate('description.select-destination-sublist-for-selected-orders')}
        style='primary'
        visible={isMovingMode}
        sublists={sublists}
        handleClose={handleCancelMoveOrders}
        handleConfirm={sublistId => {
          handleMoveSelectedOrdersToSublist(sublistId);
        }}
      />

      {
        !!orderToManageClothingReplacement && (
          <OrderClothingReplacementManagerModal
            title={Translate('modal.replacements-pending')}
            message={Translate('description.manage-lost-pieces-in-production')}
            importedModels={importedModels}
            order={orderToManageClothingReplacement}
            handleClose={() => {
              setOrderToManageClothingReplacement(null);
              setSelectedOrders([]);
            }}
            onUpdateOrder={(updatedOrder) => {
              setOrderToManageClothingReplacement(updatedOrder); // refresh data in modal

              const updatedSublists = updateCachedOrderInSublist({
                sublists,
                updatedOrder,
                targetSublistId: updatedOrder.sublist_id!
              });

              onUpdateSublists(updatedSublists);
            }}
          />
        )
      }

      {selectionMode && importedModels.length > 0 && (
        <StickyMenuBar countSelectedItems={selectedOrders.length}>
          <SplitButton
            size='sm'
            icon={IconsCatalog.times}
            title={Translate('actions.cancel')}
            color='light'
            handleClick={handleExitSelectionMode}
          />

          <SplitButton
            size='sm'
            icon={IconsCatalog.move}
            title={Translate('actions.move-selected')}
            color='primary'
            marginLeft
            handleClick={() => {
              setIsMovingMode(true);
            }}
          />

          <SplitButton
            size='sm'
            icon={IconsCatalog.trash}
            title={Translate('actions.delete-selected')}
            color='danger'
            marginLeft
            handleClick={() => {
              setShowConfirmDeleteSelectedOrders(true);
            }}
          />
        </StickyMenuBar>
      )}

      <ContextMenuProvider>
        <ContextMenuViewer
          id='table-order-manager'
          options={contextMenuOptionsForOrder}
          disabled={selectionMode || isEditingOrder || editorSettings?.final_client_readonly}
        />

        <div ref={sublistsContainerRef} style={screenshotMode ? screenshotStyleSheet : {}}>
          {sublists.map((sublist, sublistIndex) => (
            <CollapsableCard
              startWithClosedPanel={startWithClosedPanel}
              key={sublistIndex}
              header={
                <SublistCollapsableCardHeader
                  title={sublist.title}
                  sublistIndex={sublistIndex}
                  projectReport={projectReport}
                  preferences={preferences}
                  sublistFinishingOptions={sublist.selected_finishing_options}
                  hidePrices={shouldHidePrices}
                />
              }
              id={`sublist-${sublistIndex}`}
              onCollapseChange={collapsed => {
                onSublistCollapseChange({ id: sublist.id, title: sublist.title, collapsed });
              }}
            >
              {!!sublist.token && !screenshotMode && (
                <div className='row mb-3'>
                  <div className='col'>
                    <SplitButton
                      size='sm'
                      color='primary'
                      title={Translate('actions.copy-json-link')}
                      icon={IconsCatalog.copy}
                      handleClick={() => {
                        handleCopySublistUrl(sublist);
                      }}
                    />
                    <SplitButton
                      size='sm'
                      color='primary'
                      marginLeft
                      title={Translate('modal.csv-link')}
                      icon={IconsCatalog.copy}
                      handleClick={() => {
                        handleCopySublistUrlCSV(sublist);
                      }}
                    />
                  </div>
                </div>
              )}

              <div className='table-responsive' key={sublist.id}>
                {!screenshotMode && isCompanyEditor && (
                  <WrapperList className='mb-3'>
                    <small className='d-block p-1 border border-left-warning w-100'>
                      {Translate('description.order-left-yellow-border-meaning')}
                    </small>

                    {card ? <DismemberInCard card={card} sublist={sublist} /> : null}
                  </WrapperList>
                )}

                <Tabs
                  data={[
                    {
                      id: `tab-sublist-layout-${sublist.id}`,
                      label: 'Layout',
                      active: !!sublist.layout,
                      hidden: !sublist.layout,
                      icon: IconsCatalog.image,
                      content: (
                        <div className='row mx-0 px-0'>
                          <div className='col text-center'>
                            {sublist.layout && (
                              <img src={sublist.layout.file} className='img-fluid shadow-sm rounded my-3' />
                            )}
                          </div>
                        </div>
                      )
                    },
                    {
                      id: `tab-orders-${sublist.id}`,
                      label: Translate('labels.orders'),
                      active: !sublist.layout,
                      icon: IconsCatalog.list,
                      content: (
                        <TableOrderEditor
                          isSynching={isSynching}
                          sublist={sublist}
                          sublists={sublists}
                          sublistIndex={sublistIndex}
                          importedModels={importedModels}
                          screenshotMode={screenshotMode}
                          selectedOrders={selectedOrders}
                          setSelectedOrders={setSelectedOrders}
                          selectionMode={selectionMode}
                          editorSettings={editorSettings}
                          handleSortOrder={handleSortOrder}
                          handleSortByClothingSize={handleSortByClothingSize}
                          preferences={preferences}
                          projectReport={projectReport}
                          hidePrices={shouldHidePrices}
                        />
                      )
                    },
                    {
                      id: `tab-sublist-finishing-options-${sublist.id}`,
                      label: Translate('labels.finishing-options'),
                      hidden: sublist.selected_finishing_options.length === 0,
                      icon: IconsCatalog.cut,
                      content: <SublistFinishingOptionsViewer sublist={sublist} importedModels={importedModels} alignLeft />
                    },
                    {
                      id: `tab-exclusive-pricing-sublist-${sublist.id}`,
                      label: Translate('labels.exclusive-prices'),
                      hidden: hideExclusiveModelPricingTab,
                      icon: user?.can_use_exclusive_model_pricing === true ? IconsCatalog.dollar : IconsCatalog.lock,
                      content: (
                        <ExclusivePricingSublistEditor
                          sublist_id={sublist.id!}
                          isLockedToUser={!user?.can_use_exclusive_model_pricing}
                          importedModels={importedModels}
                          exclusiveModelsPricingInformations={sublist.exclusive_model_pricing}
                          usingExclusiveModelPricing={sublist.use_exclusive_model_pricing}
                          onSaveModelsPricingInformations={(updatedModelPricingInformations, useExclusiveModelPricing) => {
                            handleUpdateCachedSublistModelPricingInformations(
                              sublist,
                              updatedModelPricingInformations,
                              useExclusiveModelPricing
                            );
                          }}
                        />
                      )
                    },
                    {
                      id: `tab-sublist-3d-view-${sublist.id}`,
                      label: Translate('labels.3d-view'),
                      hidden: !sublist.view_3d_url,
                      icon: IconsCatalog.unity,
                      content: <iframe src={sublist.view_3d_url} className='w-100 border-none' height={700}></iframe>
                    },
                    {
                      id: `tab-sublist-steamstress-${sublist.id}`,
                      label: 'Costureiras',
                      icon: IconsCatalog.users,
                      content: <SeamstressTable sublist={sublist} />,
                      hidden: _.isEmpty(sublist.list_clothing_project_sublist_and_factions)
                    },
                    {
                      id: `tab-sublist-raw-material-${sublist.id}`,
                      label: 'Matéria prima',
                      icon: IconsCatalog.box,
                      content: <RawMaterialTable sublist={sublist} />,
                      hidden: _.isEmpty(sublist?.inventory)
                    }
                  ]}
                />
              </div>

              {screenshotMode && !!sublist.layout && (
                <div className='text-center'>
                  <img src={sublist.layout?.file} />
                </div>
              )}
            </CollapsableCard>
          ))}

          {screenshotMode && projectReport && preferences && (
            <FooterDetailsForPrintScreen
              projectTotalPrice={!preferences.screenshot_hide_prices ? projectReport.totalProjectPrice : null}
              projectTotalPriceWithDiscount={
                !preferences.report_hide_prices ? projectReport.totalProjectPriceWithDiscount : null
              }
              piecesTotalCount={projectReport.totalProjectClothes}
              preferences={preferences}
              hidePrices={shouldHidePrices}
            />
          )}
        </div>
      </ContextMenuProvider>
    </React.Fragment>
  );
}
