import { type SublistType } from '../../../../types/SublistTypes';
import { type ClothingProductionData, type Order } from '../../../../types/OrderTypes';
import { type ClothingModelType } from '../../../../types/ClothingModelType';
import { type SublistBatchToken } from '../../../../types/SublistBatchToken';

/** Creates a new sublist that will hold orders. */
export const createSublist = (name: string, sublists: SublistType[]): SublistType[] => {
  return [
    ...sublists,
    {
      title: name,
      details: null,
      orders: [],
      sorting: null,
      layout: null,
      token: null,
      exclusive_model_pricing: [],
      use_exclusive_model_pricing: false,
      selected_finishing_options: []
    }
  ];
};
/** Change the title of a choosen sublist. */
export const editSublist = (name: string, sublist_id: number, sublists: SublistType[]): SublistType[] => {
  return sublists.map(sublist => {
    if (sublist.id === sublist_id) return ({ ...sublist, title: name });
    return sublist;
  });
};

/** Delete a sublist from the project. */
export const deleteSublist = (sublists: SublistType[], sublistToDelete: SublistType | null): SublistType[] => {
  return sublists.filter(sublist => sublist.id !== sublistToDelete?.id);
};

export const findSublistIndexBySublistId = (sublists: SublistType[], sublist_id: number | undefined): number => {
  if (!sublist_id) return -1;

  const sublistIndex = sublists.findIndex(sublist => sublist.id === sublist_id);
  return sublistIndex;
};

export const generateSublistCsvFullUrl = (projecId: number, sublistToken: string): string => {
  const url = `${process.env.REACT_APP_SERVER_BASEURL}sisbolt/sublist/csv?p=${projecId}&t=${sublistToken}`;
  return url;
};

export const generateSublistJsonFullUrl = (projecId: number, sublistToken: string): string => {
  const url = `${process.env.REACT_APP_SERVER_BASEURL}sisbolt/sublist/json?p=${projecId}&t=${sublistToken}`;
  return url;
};

export const sublistHaveEmptyColumn = (sublist: SublistType, column: 'number' | 'nickname' | 'bloodType'): boolean => {
  let isEmptyColumn = true;

  for (let i = 0; i < sublist.orders.length; i++) {
    if (sublist.orders[i][column as keyof Order]) {
      isEmptyColumn = false;
      break;
    }
  }

  return isEmptyColumn;
};

const findMissingNumbers = (source: number[], target: number[]): number[] => {
  const missingNumbers: number[] = [];

  for (const num1 of source) {
    if (!target.includes(num1)) {
      missingNumbers.push(num1);
    }
  }

  return missingNumbers;
};

const generateProductionDataForMissingModels = (modelsIds: number[]): ClothingProductionData[] => {
  return modelsIds.map(modelId => ({ modelId, quantity: 0, sizeIndex: -1 }));
};

/**
 * Sometimes, for some reason, the data for clothings to produce, inside a order, is different from quantity of importes models, and it lead to crashes.
 * This service generate empty missing informations for missing models in order.
 * @param sublists sublist to repair
 * @param importedModels models to compare
 * @returns fixed sublist with generated missing models
 */
export const fixSublistsWithMissingClothesInOrders = (sublists: SublistType[], importedModels: ClothingModelType[]): SublistType[] => {
  const importedModelsIDs = importedModels.map(model => model.id);

  const fixedSublists = sublists.map(sublist => {
    sublist.orders = sublist.orders.map(order => {
      if (order.clothes.length === importedModels.length) return order;

      const usedModelsInCurrentOrder = order.clothes.map(clothe => clothe.modelId);

      const missingModelsInCurrentOrder = findMissingNumbers(importedModelsIDs, usedModelsInCurrentOrder);

      if (missingModelsInCurrentOrder.length === 0) return order;

      const generatedModelsForCurrentOrder = generateProductionDataForMissingModels(missingModelsInCurrentOrder);

      order.clothes = [...order.clothes, ...generatedModelsForCurrentOrder];

      return order;
    });

    return sublist;
  });

  return fixedSublists;
};

/**
 * Updates all cached sublists with new tokens received from backend.
 * @param sublists
 * @param sublistsTokens
 * @returns SublistType[] with updated sublists tokens
 */
export const handleUpdateSublistsTokens = (sublists: SublistType[], sublistsTokens: SublistBatchToken[]): SublistType[] => {
  const updatedSublistsWithNewTokens = sublists.map(sublist => {
    const targetUpdatedSublist = sublistsTokens.find(sublistWithNewToken => sublistWithNewToken.id === sublist.id);
    if (!targetUpdatedSublist) return sublist;
    return { ...sublist, token: targetUpdatedSublist.token };
  });

  return updatedSublistsWithNewTokens;
};
