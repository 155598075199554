import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSettings } from '../../../../contexts/SettingsContext';
import { useHeaderBackButton } from '../../../../contexts/HeaderBackButtonContext';
import SavePreferencesButton from '../../../../components/Buttons/SavePreferencesButton';
import ScreenshotSettingsModal from '../../../../components/Modals/ScreenshotSettingsModal';
import SplitButton from '../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../components/IconsCatalog';
import GenderPrefix from './GenderPrefix';
import { useUser } from '../../../../contexts/UserContext';
import { useAppTranslation } from '../../../../contexts/TranslationContext';
import CompanyLogo from '../../../../components/CompanyLogo';
import Tabs from '../../../../components/Tabs';
import SwitchInput from '../../../../components/Forms/SwitchInput';
import ProjectReportSettings from '../ProjectReport/components/ProjectReportSettings';
import { type ProjectReportSettingsType } from '../../../../types/Report/ProjectReportSettingsType';
import { toast } from 'react-hot-toast-promise';
import TextInput from '../../../../components/Forms/TextInput';
import CurrencySymbols from '../../../../utils/CurrencySymbols';
import TextAreaInput from '../../../../components/Forms/TextAreaInput';
import FinishingOptionsManager from './FinishingOptionsManager';
import { getServerErrorMessageFromResponse } from '../../../../utils/helper';
import ProjectStatusManager from './ProjectStatusManager';
import ClothingReplacementOptionsManager from './ClothingReplacementOptionsManager';
import { useSearchParams } from 'react-router-dom';

export default function OrderSettings() {
  const { preferences, setPreferences, isPreferencesSynched, isPreferencesSynching, savePreferences } = useSettings();
  const { showBackButton, hideBackButton } = useHeaderBackButton();
  const { isEmployeeAccount } = useUser();
  const { Translate } = useAppTranslation();

  const [searchParams] = useSearchParams();

  const [screenshotSettingsModalOpen, setScreenshotSettingsModalOpen] = useState(false);

  const [reportSettings, setReportSettings] = useState<ProjectReportSettingsType>(() => ({
    report_hide_order_number: preferences.report_hide_order_number,
    report_hide_prices: preferences.report_hide_prices,
    report_show_gender_colors: preferences.report_show_gender_colors,
    report_show_layout_below_sublist: preferences.report_show_layout_below_sublist,
    report_show_one_gender_per_line: preferences.report_show_one_gender_per_line,
    report_print_sublists_new_page: preferences.report_print_sublists_new_page
  }));

  const selectedTabQueryParam = useMemo(() => {
    return searchParams.get('tab');
  }, [searchParams]);

  const handleSavePreferences = useCallback(() => {
    toast.promise(savePreferences(), {
      loading: Translate('progress.saving-wait'),
      success: () => {
        return Translate('toast.preferences-saved');
      },
      error: getServerErrorMessageFromResponse
    });
  }, [Translate, savePreferences]);

  useEffect(() => {
    showBackButton();

    return () => {
      hideBackButton();
    };
  });

  if (isEmployeeAccount) {
    return <h3>{Translate('labels.no-access-permission')}</h3>;
  }

  return (
    <React.Fragment>
      <ScreenshotSettingsModal
        visible={screenshotSettingsModalOpen}
        handleClose={() => {
          setScreenshotSettingsModalOpen(false);
        }}
        handleConfirm={() => {
          setScreenshotSettingsModalOpen(false);
        }}
      />

      <h1 className='h3 mb-2 text-gray-800'>{Translate('labels.settings')}</h1>

      <p className='mb-4'>{Translate('description.order-settings-page-header')}</p>

      <Tabs
        data={[
          {
            id: 'tab-company-logo',
            label: Translate('labels.logo'),
            active: selectedTabQueryParam === null,
            icon: IconsCatalog.image,
            content: (
              <React.Fragment>
                <div className='row mt-2'>
                  <div className='col-sm-12 col-md-8'>
                    <CompanyLogo />
                  </div>
                </div>
              </React.Fragment>
            )
          },
          {
            id: 'tab-orders',
            label: Translate('labels.orders'),
            icon: IconsCatalog.list,
            content: (
              <React.Fragment>
                <div className="container-fluir">
                  <div className='row mt-2'>
                    <div className='col-sm-12 col-md-6'>

                      <h5 className='text-primary font-weight-bold'>{Translate('labels.main-table')}</h5>

                      <SwitchInput
                        id='display-gender-colors'
                        label={Translate('actions.show-gender-colors')}
                        checked={preferences.show_gender_colors}
                        handleChange={(checked) => {
                          setPreferences({ ...preferences, show_gender_colors: checked });
                        }}
                      />

                      <h5 className='text-primary font-weight-bold mt-4'>{Translate('labels.acceptance-message')}</h5>

                      <TextAreaInput
                        id='acceptance-message'
                        label={Translate('description.acceptance-message-description')}
                        value={preferences.acceptance_message}
                        maxLength={255}
                        handleChange={({ target }) => {
                          setPreferences({ ...preferences, acceptance_message: target.value });
                        }} />

                      <SavePreferencesButton />
                    </div>
                  </div>

                </div>
              </React.Fragment>
            )
          },
          {
            id: 'tab-finishing-options-manager',
            label: Translate('labels.finishing-option'),
            icon: IconsCatalog.cut,
            content: <FinishingOptionsManager />
          },
          {
            id: 'tab-gender-prefix',
            label: Translate('labels.prefixes'),
            icon: IconsCatalog.autoprefixer,
            content: (
              <React.Fragment>
                <div className='row mt-2'>
                  <div className='col-sm-12 col-md-8'>
                    <h5 className='text-primary font-weight-bold'>{Translate('labels.gender-prefix')}</h5>
                    <GenderPrefix />
                  </div>
                </div>
              </React.Fragment>
            )
          },
          {
            id: 'tab-screenshot-settings',
            label: Translate('labels.screenshot'),
            icon: IconsCatalog.camera,
            content: (
              <React.Fragment>
                <div className='row mt-2'>
                  <div className='col-sm-12 col-md-8'>
                    <SplitButton
                      title={Translate('labels.settings')}
                      color='primary'
                      icon={IconsCatalog.camera}
                      handleClick={() => {
                        setScreenshotSettingsModalOpen(true);
                      }}
                    />
                  </div>
                </div>
              </React.Fragment>
            )
          },
          {
            id: 'tab-report-preferences',
            label: Translate('labels.report'),
            icon: IconsCatalog.newspaper,
            content: (
              <React.Fragment>
                <div className='row mt-2'>
                  <div className='col-sm-12 col-md-8'>
                    <h5 className='text-primary font-weight-bold'>{Translate('labels.preferences')}</h5>

                    <ProjectReportSettings
                      hidePrintButton
                      currentSettings={reportSettings}
                      onChange={(updatedReportSettings) => {
                        setReportSettings(updatedReportSettings);
                        setPreferences({ ...preferences, ...updatedReportSettings });
                      }} />

                    {!isPreferencesSynched && (
                      <SplitButton
                        disabled={isPreferencesSynching}
                        color='primary'
                        size='sm'
                        title={Translate('actions.save-preferences')}
                        icon={IconsCatalog.check}
                        handleClick={() => {
                          handleSavePreferences();
                        }}
                      />
                    )}

                  </div>
                </div>
              </React.Fragment>
            )
          },
          {
            id: 'tab-currency-symbols',
            label: Translate('labels.coin'),
            icon: IconsCatalog.coins,
            content: (
              <React.Fragment>
                <div className='row mt-2'>
                  <div className='col-sm-12 col-md-8'>
                    <h5 className='text-primary font-weight-bold'>{Translate('labels.predefined-options')}</h5>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="selected-currency"
                        id="currency-real"
                        checked={preferences.currency === CurrencySymbols.BrazilianReal}
                        value={CurrencySymbols.BrazilianReal}
                        onChange={({ target }) => {
                          setPreferences({ ...preferences, currency: target.value.trim() });
                        }} />

                      <label className="form-check-label" htmlFor="currency-real">
                        <strong>{CurrencySymbols.BrazilianReal}</strong> Real Brasileiro
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="selected-currency"
                        id="currency-dollar"
                        value={CurrencySymbols.UsDollar}
                        checked={preferences.currency === CurrencySymbols.UsDollar}
                        onChange={({ target }) => {
                          setPreferences({ ...preferences, currency: target.value.trim() });
                        }} />
                      <label className="form-check-label" htmlFor="currency-dollar">
                        <strong>{CurrencySymbols.UsDollar}</strong> Dollar
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="selected-currency"
                        id="currency-euro"
                        value={CurrencySymbols.Euro}
                        checked={preferences.currency === CurrencySymbols.Euro}
                        onChange={({ target }) => {
                          setPreferences({ ...preferences, currency: target.value.trim() });
                        }} />
                      <label className="form-check-label" htmlFor="currency-euro">
                        <strong>{CurrencySymbols.Euro}</strong> Euro
                      </label>
                    </div>

                    <div className="row mt-2">
                      <div className="col-auto">

                        <TextInput
                          id='custom-currency'
                          label={Translate('labels.or-type-here')}
                          placeholder={Translate('labels.currency-placeholder-example')}
                          value={preferences.currency}
                          onChange={({ target }) => {
                            setPreferences({ ...preferences, currency: target.value.trim() });
                          }} />
                      </div>
                    </div>

                    <SavePreferencesButton />
                  </div>
                </div>
              </React.Fragment>
            )
          },
          {
            id: 'tab-clothing-replacement-options',
            label: Translate('labels.pending'),
            active: selectedTabQueryParam === 'replacements',
            icon: IconsCatalog.redo,
            content: (
              <React.Fragment>
                <div className='row mt-2'>
                  <div className='col'>
                    <h5 className='text-primary font-weight-bold'>Gerencie as peças de reposição.</h5>

                    <ClothingReplacementOptionsManager preferences={preferences}/>
                  </div>
                </div>
              </React.Fragment>
            )
          },
          {
            id: 'tab-project-status',
            label: 'Status',
            icon: IconsCatalog.hashtag,
            content: (
              <React.Fragment>
                <div className='row mt-2'>
                  <div className='col'>
                    <h5 className='text-primary font-weight-bold'>{Translate('labels.manage-available-project-statuses')}</h5>

                    <ProjectStatusManager />
                  </div>
                </div>
              </React.Fragment>
            )
          }
        ]}
      />

    </React.Fragment>
  );
}
