import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-hot-toast-promise';
import { type TableOrderManagerType } from '../..';
import { useAppTranslation } from '../../../../../../../../contexts/TranslationContext';
import { type SublistType } from '../../../../../../../../types/SublistTypes';
import SpanButton from '../../../../../../../../components/Buttons/SpanButton';
import { type Order } from '../../../../../../../../types/OrderTypes';
import { type HandleSortOrderParams } from '../../../../../../../../types/HandleSortOrderParams';
import ClothingIconViewer from '../../../../../../../../components/ClothingIconViewer';
import { type SortByClothingSizeParams } from '../../../../../../../../types/SortByClothingSizeParams';
import ColorableRow from '../../../../../../../../components/Tables/ColorableRow';
import { getColorByGender } from '../../../../../../../../helpers/orders';
import { getSizeNameFromModelId } from '../../../../services/modelService';
import SublistFooter from '../SublistFooter';
import { sublistHaveEmptyColumn } from '../../../../../services/sublistService';
import { displayAsCurrency, getServerErrorMessageFromResponse } from '../../../../../../../../utils/helper';

type TableOrderEditorType = Pick<TableOrderManagerType, 'screenshotMode' | 'projectReport' | 'editorSettings' | 'sublists' | 'importedModels' | 'preferences'> & {
  sublistIndex: number;
  selectionMode: boolean;
  selectedOrders: Order[];
  setSelectedOrders: React.Dispatch<React.SetStateAction<Order[]>>;
  sublist: SublistType;
  isSynching: boolean;
  hidePrices: boolean;
  handleSortOrder: (params: HandleSortOrderParams) => void;
  handleSortByClothingSize: (params: SortByClothingSizeParams) => void;
}

export default function TableOrderEditor({
  screenshotMode,
  projectReport,
  sublistIndex,
  selectionMode,
  selectedOrders,
  setSelectedOrders,
  sublist,
  isSynching,
  hidePrices,
  editorSettings,
  handleSortOrder,
  handleSortByClothingSize,
  sublists,
  importedModels,
  preferences
}: TableOrderEditorType) {
  const { Translate } = useAppTranslation();

  const numberColumnVisibility = useMemo(() => (sublistHaveEmptyColumn(sublist, 'number') ? 'd-none' : ''), [sublist]);

  const nicknameColumnVisibility = useMemo(() => (sublistHaveEmptyColumn(sublist, 'nickname') ? 'd-none' : ''), [sublist]);

  const bloodTypeColumnVisibility = useMemo(() => (sublistHaveEmptyColumn(sublist, 'bloodType') ? 'd-none' : ''), [sublist]);

  const showBackgroundColorInColorableRow = useMemo(() => {
    if (!preferences) return false;

    const { screenshot_show_gender_colors, show_gender_colors } = preferences;

    if (screenshotMode) return screenshot_show_gender_colors;
    return show_gender_colors;
  }, [preferences, screenshotMode]);

  const handleCheckEntireSublist = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, sublist: SublistType) => {
      const { checked } = event.target;

      if (checked) {
        const nonDuplicatedOrders = sublist.orders.filter(currentOrder => !selectedOrders.includes(currentOrder));
        if (nonDuplicatedOrders.length === 0) return;

        setSelectedOrders([...selectedOrders, ...nonDuplicatedOrders]);
        return;
      }

      const updatedSelectedOrders = selectedOrders.filter(selectedOrder => !sublist.orders.includes(selectedOrder));
      setSelectedOrders(updatedSelectedOrders);
    },
    [selectedOrders, setSelectedOrders]
  );

  const renderClothingDataColumns = useCallback(
    (order: Order) => {
      try {
        return order.clothes.map((clothe, index) => {
          const { modelId, sizeIndex, quantity } = clothe;
          const sizeName = getSizeNameFromModelId({
            gender: order.gender,
            models: importedModels,
            modelId,
            sizeIndex,
            Translate
          });

          return (
            <td className='text-center' key={index}>
              {quantity > 0 ? `${sizeName}-${quantity}` : ''}
            </td>
          );
        });
      } catch (err) {
        toast.error(getServerErrorMessageFromResponse(err));
      }
    },
    [Translate, importedModels]
  );

  const handleCheckOrder = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, order: Order) => {
      const { checked } = event.target;

      if (checked) {
        setSelectedOrders([...selectedOrders, order]);
        return;
      }

      const updatedSelectedOrders = selectedOrders.filter(currentOrder => currentOrder.id !== order.id);
      setSelectedOrders(updatedSelectedOrders);
    },
    [selectedOrders, setSelectedOrders]
  );

  const countPendingReplacementsInOrder = useCallback((order: Order) => {
    if (!order.clothing_replacements) return 0;

    return order.clothing_replacements
      .flatMap(replacements => replacements)
      .reduce((accumulator, currentReplacement) => {
        if (!currentReplacement.completed) return accumulator + 1;
        return accumulator;
      }, 0);
  }, []);

  const getBackgroundColorForOrderRow = useCallback((order: Order) => {
    const selectedGenderColor = getColorByGender(order.gender);
    return countPendingReplacementsInOrder(order) > 0 ? '#af4154' : selectedGenderColor;
  }, [countPendingReplacementsInOrder]);

  if (!preferences) return <></>;

  return (
    <table className='table table-bordered' id='dataTable' width='100%' cellSpacing='0'>
      <thead>
        {screenshotMode && (
          <tr>
            <th colSpan={999}>
              <strong>{Translate('labels.pieces-quantity')}: </strong>
              <span className='mr-3'>{projectReport?.sublists[sublistIndex].totalSublistClothes}</span>
            </th>
          </tr>
        )}
        <tr>
          {selectionMode ? (
            <th className='text-center'>
              <input
                type='checkbox'
                defaultChecked={false}
                onChange={event => {
                  handleCheckEntireSublist(event, sublist);
                }}
              />
            </th>
          ) : null}

          <th>
            <SpanButton
              text={Translate('labels.name')}
              disabled={editorSettings?.final_client_readonly ?? isSynching}
              handleClick={() => {
                handleSortOrder({ field: 'name', sublist, sublists, Translate });
              }}
            />
          </th>

          <th className={`text-center ${numberColumnVisibility}`}>
            <SpanButton
              text={Translate('labels.number')}
              disabled={editorSettings?.final_client_readonly ?? isSynching}
              handleClick={() => {
                handleSortOrder({ field: 'number', sublist, sublists, Translate });
              }}
            />
          </th>

          <th className={`text-center ${nicknameColumnVisibility}`}>
            <SpanButton
              text={Translate('labels.nickname')}
              disabled={editorSettings?.final_client_readonly ?? isSynching}
              handleClick={() => {
                handleSortOrder({ field: 'nickname', sublist, sublists, Translate });
              }}
            />
          </th>

          <th className={`text-center ${bloodTypeColumnVisibility}`}>{Translate('labels.blood-type')}</th>

          {importedModels.map((_, modelIndex) => (
            <th className='text-center' key={modelIndex}>
              <ClothingIconViewer
                testIdCounter={`table-order-editor-clothing-icon-viewer-sublist-${sublistIndex}-model-${modelIndex}`}
                labelAsTooltip
                quantity={projectReport?.sublists[sublistIndex]?.eachModelQuantity[modelIndex]}
                icon={importedModels[modelIndex].icon.url}
                label={importedModels[modelIndex].name}
                disabled={editorSettings?.final_client_readonly ?? isSynching}
                handleClick={() => {
                  handleSortByClothingSize({ sublist, clotheIndex: modelIndex });
                }}
              />
            </th>
          ))}

          {
            !hidePrices && (
              <th className="text-center">
                <SpanButton
                  text="Total"
                  disabled={editorSettings?.final_client_readonly ?? isSynching}
                  handleClick={() => {
                    handleSortOrder({ field: 'totalValue', sublist, sublists, Translate });
                  }}
                />
              </th>
            )
          }
        </tr>
      </thead>

      <tbody data-testid={`table-order-body-sublist-${sublistIndex}`}>
      {sublist.orders.length > 0 &&
        sublist.orders.map((order, orderIndex) => {
          const currentOrderTotalPrice = projectReport?.sublists[sublistIndex].ordersTotalPrices[orderIndex] ?? 0;

          return (
              <ColorableRow
                testId={`table-order-row-${orderIndex}-sublist-${sublistIndex}`}
                key={order.id}
                isSelected={selectedOrders.includes(order)}
                color={getBackgroundColorForOrderRow(order)}
                showBackgroundColor={showBackgroundColorInColorableRow || countPendingReplacementsInOrder(order) > 0}
                title={countPendingReplacementsInOrder(order) > 0 ? Translate('status.contains-pending-replacements') : ''}
                data={order}
                marked={{
                  show: currentOrderTotalPrice === 0 && !screenshotMode,
                  color: 'warning'
                }}
              >
                {selectionMode ? (
                  <td className="text-center">
                    <input
                      type="checkbox"
                      name={sublist.id!.toString()}
                      checked={selectedOrders.includes(order)}
                      onChange={event => {
                        handleCheckOrder(event, order);
                      }}
                    />
                  </td>
                ) : null}

                <td>{order.name}</td>

                <td className={`text-center ${numberColumnVisibility}`}>{order.number}</td>

                <td className={`text-center ${nicknameColumnVisibility}`}>{order.nickname}</td>

                <td className={`text-center ${bloodTypeColumnVisibility}`}>{order.bloodType}</td>

                {renderClothingDataColumns(order)}

                {
                  !hidePrices && (
                    <td className="text-center">
                      <span>{currentOrderTotalPrice > 0 && displayAsCurrency(currentOrderTotalPrice, preferences.currency)}</span>
                    </td>
                  )
                }

              </ColorableRow>
          );
        })}

        {sublist.orders.length === 0 && (
          <tr>
            <td className="text-center" colSpan={999}>
              {Translate('status.list-is-empty-for-now')}
            </td>
          </tr>
        )}
      </tbody>

      {!hidePrices && <SublistFooter projectReport={projectReport} sublistIndex={sublistIndex} preferences={preferences} />}
    </table>
  );
};
