import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 480px));
  gap: 1rem;

  @media (max-width: 1080px) {
    grid-template-columns: repeat(3, minmax(0, 480px));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 480px));
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, minmax(0, 480px));
  }
`;

export const AddWorkspace = styled(Link)`
  align-items: center;
  background-color: var(--light-v2);
  border: 1px solid #ccc;
  border-radius: 5px;
  color: var(--dark);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 160px;
  justify-content: center;
  width: 100%;
  gap: 5px;
  transition: background-color 0.3s;

  i {
    font-size: 18px;
  }

  &:hover {
    text-decoration: none;
    color: var(--dark);
    background-color: var(--light);
  }
`;

export const WorkspaceLabel = styled.span``;

export const WrapperContent = styled.div`
  background-color: var(--background);
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  height: 160px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--light);
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const WrapperName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const WorkspaceName = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

export const WorkspaceOptions = styled.div`
  cursor: pointer;
  min-width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WorkspaceCreate = styled.div``;
export const WorkspaceCardsLength = styled.span``;
