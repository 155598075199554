import { useFormContext } from 'react-hook-form';
import {
  BadgeColumnPosition,
  CardSkeleton,
  Column,
  Container,
  ContentExample,
  LabelProcessInput,
  WrapperColumn
} from './style';

export function WorkingProcess() {
  const { watch, register, setValue } = useFormContext();

  const workingProcess = (watch('workingProcess') ?? []) as Array<{ id: number; name: string; dailyAmount: number }>;

  return (
    <Container>
      <WrapperColumn>
        {workingProcess
          .sort((a, b) => Number(a.id) - Number(b.id))
          .map((working, index, workingArray) => (
            <Column key={working.id}>
              {index > 0 && (
                <BadgeColumnPosition
                  type='button'
                  onClick={() => {
                    setValue(
                      'workingProcess',
                      workingArray.filter(workingFilter => workingFilter.id !== working.id)
                    );
                  }}
                >
                  <i className='fas fa-trash' />
                </BadgeColumnPosition>
              )}

              <div>
                <LabelProcessInput htmlFor='working_name'>Nome do processo</LabelProcessInput>
                <input
                  type='text'
                  placeholder='Nome do processo'
                  className='form-control'
                  {...register(`workingProcess.${index}.name`)}
                />
              </div>

              <div>
                <LabelProcessInput htmlFor='working_day'>Qtd diária</LabelProcessInput>
                <input
                  type='number'
                  placeholder='Quantidade diária'
                  className='form-control'
                  min={0}
                  {...register(`workingProcess.${index}.dailyAmount`)}
                />
              </div>

              <ContentExample>
                <CardSkeleton />
                <CardSkeleton />
              </ContentExample>
            </Column>
          ))}
      </WrapperColumn>
    </Container>
  );
}
