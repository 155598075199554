import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  title: yup.string().required('Nome da workspace é obrigatório'),
  officeHours: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      day: yup.number().required('Dia da semana é obrigatório'),
      hours: yup.array().of(
        yup.object().shape({
          id: yup.string(),
          start: yup.string().optional(),
          end: yup.string().optional()
        })
      )
    })
  ),
  workingProcess: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      name: yup.string().required('Nome do processo é obrigatório'),
      dailyAmount: yup.number().required('Quantidade diária é obrigatória')
    })
  )
});
