export enum IconsCatalog {
  facebook = 'fab fa-facebook-f',
  whatsapp = 'fab fa-whatsapp',
  pen = 'fas fa-pen',
  penRule = 'fas fa-pencil-ruler',
  cog = 'fas fa-cog',
  file = 'fas fa-file-alt',
  list = 'fas fa-list-alt',
  trash = 'fas fa-trash',
  save = 'fas fa-save',
  plus = 'fas fa-plus',
  play = 'fas fa-play',
  handHoldUSD = 'fas fa-hand-holding-usd',
  times = 'fas fa-times',
  sun = 'fas fa-sun',
  moon = 'fas fa-moon',
  check = 'fas fa-check',
  checkSquare = 'fas fa-check-square',
  move = 'fas fa-arrows-alt',
  tshirt = 'fas fa-tshirt',
  search = 'fas fa-search',
  eye = 'fas fa-eye',
  coins = 'fas fa-coins',
  cloudUpload = 'fas fa-cloud-upload-alt',
  hourGlass = 'fas fa-hourglass',
  arrowLeft = 'fas fa-arrow-left',
  arrowRight = 'fas fa-arrow-right',
  forms = 'fab fa-wpforms',
  image = 'fas fa-image',
  camera = 'fas fa-camera',
  undo = 'fas fa-undo',
  paperClip = 'fas fa-paperclip',
  stickyNote = 'fas fa-sticky-note',
  comment = 'fas fa-comment',
  bars = 'fas fa-bars',
  print = 'fas fa-print',
  sync = 'fas fa-sync',
  link = 'fas fa-link',
  download = 'fas fa-download',
  copy = 'fas fa-copy',
  questionCircle = 'fas fa-question-circle',
  box = 'fas fa-box',
  boxOpen = 'fas fa-box-open',
  cut = 'fas fa-cut',
  dashboard = 'fa-tachometer-alt',
  tasks = 'fas fa-tasks',
  dotsVertical = 'fas fa-ellipsis-h',
  dollar = 'fas fa-dollar-sign',
  commentsDollar = 'fas fa-comments-dollar',
  share = 'fas fa-share',
  lock = 'fas fa-lock',
  autoprefixer = 'fab fa-autoprefixer',
  newspaper = 'fas fa-newspaper',
  caretDown = 'fas fa-caret-down',
  caretUp = 'fas fa-caret-up',
  calendarDays = 'fas fa-calendar',
  invoiceDollar = 'fas fa-file-invoice-dollar',
  unity = 'fab fa-unity',
  users = 'fas fa-users',
  truck = 'fas fa-truck',
  lightBulb = 'fas fa-lightbulb',
  clone = 'fas fa-clone',
  hashtag = 'fas fa-hashtag',
  redo = 'fas fa-redo',
  exclamationTriangle = 'fas fa-exclamation-triangle'
}
