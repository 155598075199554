import {
  AddWorkspace,
  Container,
  WrapperContent,
  Row,
  WorkspaceLabel,
  WorkspaceName,
  WrapperName,
  WorkspaceOptions,
  WorkspaceCreate,
  WorkspaceCardsLength
} from './style';

export function Content() {
  return (
    <Container>
      <AddWorkspace to='add'>
        <i className='fas fa-plus'></i>
        <WorkspaceLabel>Adicionar Workspace</WorkspaceLabel>
      </AddWorkspace>

      <WrapperContent title='Workspace com o nome gigantesco'>
        <Row>
          <WrapperName>
            <WorkspaceName>Workspace com o nome gigantesco</WorkspaceName>
          </WrapperName>
          <WorkspaceOptions>
            <i className='fas fa-ellipsis-v'></i>
          </WorkspaceOptions>
        </Row>

        <Row>
          <WorkspaceCreate>11/11/2024</WorkspaceCreate>
          <WorkspaceCardsLength>3 cards</WorkspaceCardsLength>
        </Row>
      </WrapperContent>
    </Container>
  );
}
