import { uniqueId } from 'lodash';

const hours = [
  { id: uniqueId(), start: '08:00', end: '12:00' },
  { id: uniqueId(), start: '14:00', end: '18:00' }
];

export const WEEK_DAYS = [
  { id: uniqueId(), day: 0, title: 'Segunda-feira', hours },
  { id: uniqueId(), day: 1, title: 'Terça-feira', hours },
  { id: uniqueId(), day: 2, title: 'Quarta-feira', hours },
  { id: uniqueId(), day: 3, title: 'Quinta-feira', hours },
  { id: uniqueId(), day: 4, title: 'Sexta-feira', hours },
  { id: uniqueId(), day: 5, title: 'Sábado', hours: [{ id: uniqueId(), start: '', end: '' }] },
  { id: uniqueId(), day: 6, title: 'Domingo', hours: [{ id: uniqueId(), start: '', end: '' }] }
];
