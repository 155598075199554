import styled from 'styled-components';

export const Label = styled.label`
  font-size: 18px;
  font-weight: 600;
`;

export const DayContent = styled.div`
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex: 1;
`;

export const DaysContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  margin-bottom: 2rem;
  gap: 20px;

  & > ${DayContent} {
    background-color: var(--background);
  }

  @media (max-width: 1380px) {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }

  @media (max-width: 1080px) {
    grid-template-columns: repeat(1, minmax(300px, 1fr));
  }
`;
