import { useCallback, useMemo } from 'react';
import { uniqueId } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validationSchema';

import { Header } from '../components/Header';
import { Title } from './fragments/Title';
import { OfficeHours } from './OfficeHours';
import { FormContainer, Label } from './style';
import { WorkingProcess } from './WorkingProcess';
import { WEEK_DAYS } from './OfficeHours/constants';

export function AddWorkspace() {
  const methods = useForm({
    defaultValues: {
      title: '',
      officeHours: WEEK_DAYS,
      workingProcess: [
        {
          id: uniqueId(),
          name: '',
          dailyAmount: 0
        }
      ]
    },
    resolver: yupResolver(validationSchema)
  });

  const addWorkingProcess = useCallback(() => {
    methods.setValue('workingProcess', [
      ...(methods.getValues('workingProcess') ?? []),
      {
        id: uniqueId(),
        name: '',
        dailyAmount: 0
      }
    ]);
  }, [methods]);

  const disableButton = useMemo(() => {
    return !methods.formState.isValid;
  }, [methods.formState.isValid]);

  return (
    <div className='d-flex flex-column pb-5'>
      <Header title='Adicionar uma nova workspace' subtitle='Criar uma nova área de planejamento e controle da produção' />

      <FormProvider {...methods}>
        <FormContainer
          onSubmit={methods.handleSubmit(data => {
            console.log(data);
          })}
        >
          <div className='form-group'>
            <Label htmlFor='title'>Nome da workspace</Label>
            <input type='text' placeholder='nome da workspace' className='form-control' {...methods.register('title')} />
          </div>

          <Title title='Horários de Funcionamento' subtitle='Defina os horários de funcionamento da workspace' />

          <OfficeHours />

          <Title
            title='Processo de produção'
            subtitle='Adicione sua linha de produção, dando nome e quantidade diária'
            right={
              <button type='button' className='btn btn-primary' onClick={addWorkingProcess}>
                Adicionar processo
              </button>
            }
          />

          <WorkingProcess />

          <div className='d-flex justify-content-end'>
            <button type='submit' className='btn btn-primary' disabled={disableButton}>
              Criar workspace
            </button>
          </div>
        </FormContainer>
      </FormProvider>
    </div>
  );
}
