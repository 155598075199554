import { useFormContext } from 'react-hook-form';
import { DayContent, DaysContainer, Label } from './style';
import { type WEEK_DAYS } from './constants';
import { uniqueId } from 'lodash';

export function OfficeHours() {
  const { watch, register, setValue } = useFormContext();

  const weekDays = (watch('officeHours') ?? []) as typeof WEEK_DAYS;

  return (
    <DaysContainer>
      {weekDays
        .sort((a, b) => a.day - b.day)
        .map(day => (
          <DayContent key={day.id}>
            <Label>{day.title}</Label>

            <div className='d-flex flex-column' style={{ gap: 10 }}>
              {day.hours
                ?.sort((a, b) => Number(a.id) - Number(b.id))
                ?.map((hour, index, hoursArray) => (
                  <div className='d-flex align-items-end' key={index} style={{ gap: 10 }}>
                    <div className='w-100'>
                      <label htmlFor='start'>Início</label>
                      <input
                        type='time'
                        className='form-control'
                        placeholder='Início'
                        id='start'
                        defaultValue={hour?.start}
                        {...register(`officeHours.${day.day}.hours.${index}.start`)}
                      />
                    </div>

                    <div className='w-100'>
                      <label htmlFor='end'>Saída</label>
                      <input
                        type='time'
                        className='form-control'
                        placeholder='Fim'
                        id='end'
                        defaultValue={hour?.end}
                        {...register(`officeHours.${day.day}.hours.${index}.end`)}
                      />
                    </div>

                    {hoursArray.length === index + 1 && hoursArray.length > 1 && (
                      <button
                        type='button'
                        className='btn btn-danger btn-circle'
                        onClick={() => {
                          setValue(
                            `officeHours.${day.day}.hours`,
                            hoursArray.filter(hourFilter => hourFilter.id !== hour.id)
                          );
                        }}
                      >
                        <i className='fas fa-trash'></i>
                      </button>
                    )}

                    {hoursArray.length - 1 === index && (
                      <button
                        type='button'
                        className='btn btn-success btn-circle'
                        onClick={() => {
                          setValue(`officeHours.${day.day}.hours`, [
                            ...hoursArray,
                            {
                              id: uniqueId(),
                              start: '',
                              end: ''
                            }
                          ]);
                        }}
                      >
                        <i className='fas fa-plus'></i>
                      </button>
                    )}
                  </div>
                ))}
            </div>
          </DayContent>
        ))}
    </DaysContainer>
  );
}
