import SwitchInput from '../../../../../../components/Forms/SwitchInput';
import SplitButton from '../../../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../../../components/IconsCatalog';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { type ProjectReportSettingsType } from '../../../../../../types/Report/ProjectReportSettingsType';

type ProjectReportSettingsParams = {
  currentSettings: ProjectReportSettingsType;
  onChange: (updatedSettings: ProjectReportSettingsType) => void;
  hidePrintButton?: boolean;
}

export default function ProjectReportSettings({ currentSettings, onChange, hidePrintButton }: ProjectReportSettingsParams) {
  const { Translate } = useAppTranslation();

  return (
    <div className='row'>
      <div className='col'>
        <SwitchInput
          checked={currentSettings.report_hide_prices}
          id='report-hide-prices'
          label={Translate('actions.hide-prices')}
          handleChange={checked => {
            onChange({ ...currentSettings, report_hide_prices: checked });
          }}
        />

        <SwitchInput
          checked={currentSettings.report_hide_order_number}
          id='report-hide-order-number'
          label={Translate('actions.hide-order-number')}
          handleChange={checked => {
            onChange({ ...currentSettings, report_hide_order_number: checked });
          }}
        />

        <section>
          <SwitchInput
            checked={currentSettings.report_show_gender_colors}
            id='report-show-gender-colors-switch'
            label={Translate('actions.show-gender-colors')}
            handleChange={checked => {
              onChange({ ...currentSettings, report_show_gender_colors: checked });
            }}
          />

          {currentSettings.report_show_gender_colors && (
            <small className='form-text text-muted mb-2'>
              <span>{Translate('description.printing-tip-check-option')}</span>
              &nbsp;
              <strong>{Translate('description.printing-tip-background-graphics')}</strong>
            </small>
          )}

        </section>

        <SwitchInput
          checked={currentSettings.report_show_one_gender_per_line}
          id='report-separated-lines-detailed-report'
          label={Translate('actions.show-one-gender-by-line-in-report')}
          handleChange={checked => {
            onChange({ ...currentSettings, report_show_one_gender_per_line: checked });
          }}
        />

        <SwitchInput
          checked={currentSettings.report_show_layout_below_sublist}
          id='report-show-layout-images-below-lists'
          label={Translate('actions.show-layouts-below-lists')}
          handleChange={checked => {
            onChange({ ...currentSettings, report_show_layout_below_sublist: checked });
          }}
        />

        <SwitchInput
          checked={currentSettings.report_print_sublists_new_page}
          id='report-print-sublists-new-page'
          label={Translate('actions.print-sublists-new-page')}
          handleChange={checked => {
            onChange({ ...currentSettings, report_print_sublists_new_page: checked });
          }}
        />
      </div>

      <div className='col-auto'>
        {
          !hidePrintButton && (
            <SplitButton
              color='primary'
              title={Translate('actions.print')}
              icon={IconsCatalog.print}
              handleClick={() => {
                window.print();
              }}
            />
          )
        }
      </div>
    </div>
  );
}
